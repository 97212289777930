<template>
  <div id="todoList">
    <ul>
      <li v-for="(item,index) in todoList" :key="item.id">
        <div class="input-container">
          <el-checkbox
              v-model="item.isCompleted"
              @change="handleChange(item)"
          ></el-checkbox>
          <el-input
              style="width: 400px;margin-left: 5px"
              v-if="item.editable"
              v-model="item.task"
              placeholder="请输入任务名称"
              @blur="toLook(item)"
              @keyup.native.enter="toLook(item)"
              :ref="index"
          ></el-input>
          <span
              v-else
              @click="toEdit(item,index)"
              :class="item.isCompleted ? 'completed' : '' "
          >{{ item.task }}</span>

        </div>
        <el-button
            type="danger"
            class="button"
            @click="handleDelete(item.id)"
        >删除
        </el-button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["todoList"],
  data() {
    return {};
  },
  methods: {
    handleChange(item) {
      if (item.isCompleted) {
        this.$message({
          message: `恭喜你完成了${item.task}`,
          type: 'success',
          duration: 2000
        })
      }
    },
    handleDelete(id) {
      this.$emit("deleteTodo", id)
    },
    toEdit(item, index) {
      if (item.hasOwnProperty("editable")) {
        item.editable = true
      } else {
        this.$set(item, "editable", true)
      }
      this.$nextTick(() => {
        this.$refs[index][0].focus();
      })
    },
    toLook(item) {
      if (item.hasOwnProperty("editable")) {
        item.editable = false
      } else {
        this.$set(item, "editable", false)
      }
    }
  }
};
</script>

<style scoped>
#todoList {
}

ul {
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

li {
  display: flex;
  height: 38px;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #dddddd;
}

li:nth-last-child(1) {
  border-bottom: none;
}

li .input-container {
  flex: 1;
  margin-left: 5px;
}

.completed {
  text-decoration: line-through;
  color: #d9d9d9;
}

li .button {
  display: none;
}

li:hover .button {
  display: block;
}
</style>
<style>
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #d9d9d9 !important;
  border-color: #d9d9d9 !important;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #d9d9d9 !important;
}

.el-checkbox.is-bordered.is-checked {
  border-color: #d9d9d9 !important;
}

.el-checkbox__input.is-focus .is-checkbox__inner {
  border-color: #d9d9d9 !important;
}
</style>
