<template>
  <div id="app">
    <h3 style="text-align: center">todo小工具</h3>
    <todoInput @addTask="addTaskToList"></todoInput>
    <todoList v-show="undoList.length !== 0" :todoList="undoList" @deleteTodo="handlerDelete"></todoList>
    <completeHeader
        :isShowCompleted="isShowCompleted"
        @changeShowCompleted="changeShowCompleted"
        ></completeHeader>
    <todoList
        v-show="isShowCompleted"
        :todoList="completedList"
        @changeShowCompleted="changeShowCompleted"
    ></todoList>
  </div>
</template>

<script>
import todoInput from "@/components/todoInput.vue";
import todoList from "@/components/todoList.vue";
import completeHeader from "@/components/completeHeader.vue";
import {nanoid} from 'nanoid'
import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'App',
  components: {
    todoList, todoInput,
    completeHeader
  },
  data() {
    return {
      todoList: JSON.parse(localStorage.getItem("todoList")) || [],
      isShowCompleted: true
    }
  },
  methods: {
    addTaskToList(taskName) {
      this.todoList.unshift({
        id: nanoid(),
        task: taskName,
        isCompleted: false
      })
    },
    changeShowCompleted(isShowCompleted) {
      this.isShowCompleted = isShowCompleted
    },
    handlerDelete(id) {
      this.todoList = this.todoList.filter((item) => item.id !== id)
    }
  },
  computed: {
    undoList() {
      return this.todoList.filter((item) => !item.isCompleted)
    },

    completedList() {
      return this.todoList.filter((item) => item.isCompleted)
    }
  },
  watch: {
    todoList: {
      handler(todoList) {
        const myTodoList = cloneDeep(todoList)
        myTodoList.forEach((item) => {
          delete item.editable
        })
        localStorage.setItem("todoList",JSON.stringify(myTodoList))
      },
      deep: true
    }
  }
}
</script>

<style>
body {
  max-width: 540px;
  min-width: 320px;
  margin: 0 auto;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 40px;
  padding: 0 8px;
}

li {
  list-style: none;
}
</style>
