<template>
  <div id="completeHeader" @click="changeStatus" class="noselect cursor padding10">
    <span v-if="isShowCompleted"><svg t="1696131878417" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                      xmlns="http://www.w3.org/2000/svg" p-id="4793" width="16" height="16"><path
        d="M904.533333 311.466667c-17.066667-17.066667-42.666667-17.066667-59.733333 0L512 644.266667 179.2 311.466667c-17.066667-17.066667-42.666667-17.066667-59.733333 0-17.066667 17.066667-17.066667 42.666667 0 59.733333l362.666666 362.666667c8.533333 8.533333 19.2 12.8 29.866667 12.8s21.333333-4.266667 29.866667-12.8l362.666666-362.666667c17.066667-17.066667 17.066667-42.666667 0-59.733333z"
        p-id="4794"></path></svg>
    </span>
    <span v-else>
     <svg t="1696131899010" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="4971" width="16" height="16"><path
         d="M731.733333 480l-384-341.333333c-17.066667-14.933333-44.8-14.933333-59.733333 4.266666-14.933333 17.066667-14.933333 44.8 4.266667 59.733334L640 512 292.266667 821.333333c-17.066667 14.933333-19.2 42.666667-4.266667 59.733334 8.533333 8.533333 19.2 14.933333 32 14.933333 10.666667 0 19.2-4.266667 27.733333-10.666667l384-341.333333c8.533333-8.533333 14.933333-19.2 14.933334-32s-4.266667-23.466667-14.933334-32z"
         fill="#666666" p-id="4972"></path></svg>
    </span>
    已完成
  </div>
</template>

<script>
export default {
  props: ["isShowCompleted"],
  data() {
    return {};
  },
  methods: {
    changeStatus() {
      this.$emit("changeShowCompleted", !this.isShowCompleted)
    }
  }
};
</script>

<style scoped>
#completeHeader {
}
.cursor {
  cursor: pointer;
}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.padding10 {
  padding: 10px 0 0 0;
}
</style>
